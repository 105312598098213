<template>
  <b-modal id="modal-bundle" ok-only ok-title="Accept" centered size="lg" title="Tambah Bundle" @hide="handleHideModal">
    <div class="section">
      <b-container class="">
        <b-row>
          <!-- Name Bundle -->
          <b-col cols="12" md="12" class="custom__form--input mb-2">
            <label for="v-nama-barang">Nama Bundle <span class="text-danger">*</span></label>
            <b-form-group>
              <b-form-input id="v-nama-barang" v-model="formPayload.name" type="text" placeholder="Nama Kategori"
                class="custom__input" />
            </b-form-group>
          </b-col>
          <!-- Image -->
          <b-col cols="12" md="12" class="custom__form--input mb-2">
            <label for="v-image">Gambar (opsional)</label>
            <b-form-group>
              <b-form-file v-model="formPayload.image" placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..." class="" />
            </b-form-group>
          </b-col>
          <!-- Date From -->
          <b-col cols="12" md="12" class="custom__form--input mb-2">
            <label for="v-date-from">Berlaku dari</label>
            <b-form-group>
              <b-form-datepicker id="v-date-from" v-model="formPayload.start_date" class="custom__input" />
            </b-form-group>
          </b-col>
          <!-- Date End -->
          <b-col cols="12" md="12" class="custom__form--input mb-2">
            <label for="v-date-end">Berlaku Sampai</label>
            <b-form-group>
              <b-form-datepicker id="v-date-end" v-model="formPayload.end_date" class="custom__input" />
            </b-form-group>
          </b-col>
          <!-- Total Price -->
          <b-col cols="12" md="12" class="custom__form--input mb-2">
            <label for="v-total-price">Total Harga <span class="text-danger">*</span></label>
            <b-form-group>
              <input id="v-total-price" class="focus-0 custom__input pl-1 w-100" type="text" :value="price"
                @input="getPrice" @change="getPrice" placeholder="Total Harga">
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <h4>Daftar Konten Bundle</h4>
            <b-button class="mb-1" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="repeateAgain">
              <feather-icon icon="PlusIcon" class="mr-25" />
              <span>Tambah produk</span>
            </b-button>
            <b-form ref="form" class="repeater-form" @submit.prevent="repeateAgain">
              <!-- Row Loop -->
              <b-row v-for="(item, index) in formPayload.items" :id="item.id" :key="item.id" ref="row">

                <!-- Item Name -->
                <b-col class="">
                  <b-form-group class="custom__form--input mb-0" label-for="item-name">
                    <v-select v-model="formPayload.items[index].product_uuid" label="title" :options="optionsProduct"
                      placeholder="Pilih Produk" @search="onSearchProduct" />
                    <!-- <b-form-input class="custom__input" id="item-name" type="text" placeholder="Produk" /> -->
                  </b-form-group>
                  <p class="text-dark font-weight-bold mb-25" v-if="formPayload.items[index].product_uuid">Harga satuan:
                    <span v-if="formPayload.items[index].product_uuid.standard_price > 0">{{ formPayload.items[index].product_uuid.standard_price | formatAmount }}</span>
                    <span v-else>-</span>
                  </p>
                </b-col>

                <!-- Warehouse -->
                <b-col class="" lg="4">
                  <b-form-group class="custom__form--input mb-0" label-for="cost">
                    <!-- <b-form-select v-model="items[index].warehouse_uuid" :options="optionsWarehouse"
                      placeholder="Pilih Lokasi SKU" /> -->
                    <v-select v-model="formPayload.items[index].warehouse_uuid" label="text"
                      :reduce="option => option.value" :options="optionsWarehouse" placeholder="Pilih Lokasi SKU"
                      @search="onSearchWarehouse" />
                    <!-- <b-form-input class="custom__input" id="cost" type="number" placeholder="Gudang" /> -->
                  </b-form-group>
                  <p class="text-danger mb-25" v-if="items[index] && items[index].stock == 0">Stock digudang
                    kosong</p>
                </b-col>

                <!-- Profession -->
                <b-col class="">
                  <b-form-group class="custom__form--input">
                    <b-form-spinbutton class="custom__input" :disabled="items[index] && items[index].stock == 0"
                      v-model="formPayload.items[index].qty" value="1" min="1" max="100" />
                  </b-form-group>
                </b-col>

                <!-- Remove Button -->
                <b-col class="mt-50">
                  <b-button class="d-flex" v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger"
                    @click="removeItem(index)">
                    <feather-icon icon="XIcon" class="mr-25 w-100" />
                    <span>Hapus</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>

            </b-form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <template #modal-footer="{ }">
      <div v-if="isLoading">
        <br>
        <b-spinner class="mb-2" variant="primary" /><br>
      </div>
      <b-button variant="primary" @click="editUuid ? updateItem() : createItem()">
        Simpan Bundle
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import _ from 'lodash'
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BCard, BFormFile, BFormDatepicker, BFormSpinbutton, BFormSelect, BFormSelectOption, BForm, BSpinner,
} from 'bootstrap-vue'

import { formatRupiah } from '@/auth/utils'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from "vue-select";
import { destroyed } from 'vue-echarts'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    BCard,
    BFormFile,
    BFormDatepicker,
    BFormSpinbutton,
    BFormSelect,
    BFormSelectOption,
    BForm,
    vSelect,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    iconBank: {
      type: String,
    },
    isLoading: {
      type: Boolean
    }
  },
  mixins: [heightTransition],
  setup() {
    return {
      formatRupiah
    }
  },
  watch: {
    iconBank(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.price = value.price
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
    'formPayload.items': {
      handler(value, oldValue) {
        if (value.length) {
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            if (!element.qty) {
              this.checkStock(index, element.product_uuid, element.warehouse_uuid)
            }
          }
        }
      },
      deep: true,
    },
  },
  data() {
    return {
      formPayload: {
        items: []
      },
      photo_url: '',
      editUuid: null,
      items: [],
      nextTodoId: 2,
      optionsWarehouse: [],
      optionsProduct: [],
      price: ''
    }
  },
  mounted() {
    // this.initTrHeight()
    this.getWarehouse()
    this.getItems()
  },
  created() {
    // window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    // window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    handleHideModal(event) {
      this.items = []
      this.formPayload = {
        items: []
      }
      this.$emit('getPayload', this.formPayload)
    },
    checkStock(index, product_uuid, warehouse_uuid) {
      if (product_uuid && warehouse_uuid) {
        this.$http.get(`warehouse-stock/${warehouse_uuid}?product_uuid=${product_uuid}`)
          .then((result) => {
            this.items[index].stock = result.data.data.stock
          }).catch((err) => {
            console.log(err);
          });
      }
    },
    onSearchProduct(search, loading) {
      if (search.length) {
        loading(true)
        this.searchProduct(loading, search, this)
      }
    },
    searchProduct: _.debounce((loading, search, vm) => {
      vm.$store.dispatch("cashier/getData", {
          uuid: '',
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsProduct = response.data.data.data.map((e) => {
            return {
              title: e.alias_name,
              label: e.uuid,
              standard_price: e.standard_price
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
      loading(false)
    }, 300),
    async getItems() {
      try {
        const result = await this.$store.dispatch('cashier/getData', {
          uuid: '',
          params: {
            per_page: 10,
          },
        });

        this.optionsProduct = result.data.data.data.map(element => ({
          title: element.alias_name,
          label: element.uuid,
          standard_price: element.standard_price
        }));

      } catch (err) {
        console.log(err);
      }
    },
    onSearchWarehouse(search, loading) {
      if (search.length) {
        loading(true)
        this.searchWarehouse(loading, search, this)
      }
    },
    searchWarehouse: _.debounce((loading, search, vm) => {
      vm.$store.dispatch("warehouse/getData", {
          uuid: '',
          params: {
            search,
          },
        })
        .then((response) => {
          vm.optionsWarehouse = response.data.data.data.map((e) => {
            return {
              text: e.name,
              value: e.uuid,
            };
          });
          loading(false);
        })
        .catch((error) => {
          loading(false);
        });
      loading(false)
    }, 300),
    getWarehouse() {
      this.$store
        .dispatch("warehouse/getData", {
          uuid: "",
          params: "",
        })
        .then((result) => {
          const warehouses = result.data.data
          for (let index = 0; index < warehouses.length; index++) {
            const element = warehouses[index]
            this.optionsWarehouse.push({
              text: element.name,
              value: element.uuid,
            })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    repeateAgain() {
      this.formPayload.items.push({
        qty: 0,
        warehouse_uuid: '',
        product_uuid: ''
      })
      this.items.push({
        stock: null,
      })
      // this.$nextTick(() => {
      //   this.trAddHeight(this.$refs.row[0].offsetHeight)
      // })
    },
    removeItem(index) {
      this.formPayload.items.splice(index, 1)
      this.items.splice(index, 1)
      // this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    // initTrHeight() {
    //   this.trSetHeight(null)
    //   this.$nextTick(() => {
    //     this.trSetHeight(this.$refs.form.scrollHeight)
    //   })
    // },
    getPhoto(value) {
      this.formPayload.image = value.photo
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-item')
    },
    getPrice() {
      const rupiah = document.getElementById('v-total-price');
      if (rupiah?.value) {
        rupiah.value = this.formatRupiah(rupiah.value, "");
        this.formPayload.price = parseFloat(rupiah.value.replaceAll('.', ''))
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 140px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.repeater-form {
  .vs__selected-options {
    flex-wrap: nowrap;
  }
  .vs__selected {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 90px;
  }
}
</style>
